// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-firehose-all-js": () => import("./../src/templates/firehose-all.js" /* webpackChunkName: "component---src-templates-firehose-all-js" */),
  "component---src-templates-firehose-tag-js": () => import("./../src/templates/firehose-tag.js" /* webpackChunkName: "component---src-templates-firehose-tag-js" */),
  "component---src-templates-firehose-category-js": () => import("./../src/templates/firehose-category.js" /* webpackChunkName: "component---src-templates-firehose-category-js" */),
  "component---src-templates-firehose-category-tag-js": () => import("./../src/templates/firehose-category-tag.js" /* webpackChunkName: "component---src-templates-firehose-category-tag-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-duplicates-js": () => import("./../src/pages/duplicates.js" /* webpackChunkName: "component---src-pages-duplicates-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

